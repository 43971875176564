<template>
  <div class="EncuestaForm">
    <v-card
      outlined
      :loading="cargando"
      :disabled="cargando"
    >
      <v-card-title>Dinos cómo fue tu experiencia en Dental Master</v-card-title>
      <v-card-text>
         1. El servicio que te brindaron fue amable y profesional  (poner caritas para saber que si)
        2.- Te dieron explicación de tu tratamiento odontológico y presupuesto

        3.-Recomendarías a Dental Master con tus familiares y amigos
        {_ÑB
        PREGUNTA CERRADA
        4. Por qué medio conociste DENTAL MASTER
        a) Otro doctor me recomendó    b) Amigos o familiares c) Redes sociales (facebook, instagram)   d)Cine  e) Otro medio____________

        PREGUNTA ABIERTA
        5. Recomendaciones o sugerencias para Dental Master 
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <v-btn class="primary">Enviar encuesta</v-btn>
      </v-card-actions>
    </v-card>
  </div>
</template>
<script>
import standard from "@/mixins/standard.js";
export default {
  name: "EncuestaForm",
  mixins: [standard],
  components: {},
  data() {
    return {};
  },
  methods: {},
  created() {},
};
</script>
